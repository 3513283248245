
import { hydrateCSRTemplate } from "@fiverr-private/layout_lib/CSRTemplate";

import CategoriesMenu from '/home/circleci/app/packages/layout-categories-menu-component/src/entries/CategoriesMenu';

const element = typeof document !== 'undefined' && document.querySelector('#CategoriesMenu');
if (element) {
    const props = window.initialData['CategoriesMenu'];

    hydrateCSRTemplate(CategoriesMenu, props, element, 'CategoriesMenu', 'CategoriesMenu');
}
export default CategoriesMenu;
